import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import "./AddSocietyModal.css";
import { useDispatch, useSelector } from "react-redux";
import "./AddSocietyModal.css";
import { addSociety } from "../../../../../Redux/Slice/societySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Utility from "../../../../../utils/Utility";

export default function AddSocietyModal(props) {
  const { isAddSocietyLoading } = useSelector((state) => state.society);
  const dispatch = useDispatch();

  const [societyCode, setSocietyCode] = useState("");
  const [societyName, setSocietyName] = useState("");
  const [societyCodeErr, setSocietyCodeErr] = useState("");
  const [societyNameErr, setSocietyNameErr] = useState("");

  const validation = () => {
    if (societyCode?.trim() === "" || societyCode?.length < 4) {
      setSocietyNameErr("");
      setSocietyCodeErr("Enter New Society Code");
      return false;
    } else if (societyName?.trim() === "" || societyName?.length < 5) {
      setSocietyCodeErr("");
      setSocietyNameErr("Enter New Society Name");
      return false;
    } else {
      setSocietyCodeErr("");
      setSocietyNameErr("");
      return true;
    }
  };

  const handleAddNewSociety = async () => {
    if (validation()) {
      const data = {
        societyCode: societyCode,
        societyName: societyName,
      };

      dispatch(addSociety({ data: data, token: props.token }))
        .then(unwrapResult)
        .then((res) => {
          Utility.sToast("New Society Added !");
          setSocietyCode("");
          setSocietyName("");
          props.getSocietyFun();
          props.onHide();
        })
        .catch((err) => {
          if (err?.message === "Network Error") {
            Utility.eToast("Network error! Please try again later.");
          } else {
            Utility.eToast("Unable to Add Society ! Please try again later.");
          }
        });
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header  style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px"}}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "#ffffff" }}
        >
          Add New Society
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label id="societyLabel">
              Society Code <span id="errText">*</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Society Code"
              value={societyCode}
              onChange={(e) => setSocietyCode(e.target.value)}
            />
            <div id="errText">{societyCodeErr}</div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label id="societyLabel">
              Society Name <span id="errText">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Society Name"
              value={societyName}
              onChange={(e) => setSocietyName(e.target.value)}
            />

            <div id="errText">{societyNameErr}</div>
          </Form.Group>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              id="close-add-society"
              onClick={() => {
                props.onHide();
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              id="add-society-button"
              disabled={isAddSocietyLoading}
              onClick={handleAddNewSociety}
              variant="success"
            >
              Add{" "}
              {isAddSocietyLoading && (
                <Spinner size="sm" variant="light" className="ms-1" />
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
